import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { QCFormMapping } from '../../interfaces/qc-form-mappings/qc-form-mapping';
import { QCFormMappingForCreation } from '../../interfaces/qc-form-mappings/qc-form-mapping-for-creation';
import { QCFormMappingForUpdate } from '../../interfaces/qc-form-mappings/qc-form-mapping-for-update';

@Injectable({
  providedIn: 'root'
})
export class QcFormMappingsRepositoryService extends PaginatedRepository<QCFormMapping> {
  protected override _baseLink: string;

  constructor(
      http: HttpClient,
      messageService: ToastrService,
      @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      
      super(http,messageService);

      this._currentParams = new HttpParams()
          .set('pageSize', 10);

      this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/QCFormMappings`;
      this._baseLink = this._baseLinkTemplate;
  }

  private generateHeaders = () => {
      return {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
  }

  public createQCFormMapping = (corporationID: string, qCFormMapping: QCFormMappingForCreation) => {
    var urlSlug = `/Corporations/${corporationID}`

    return this.http.post<QCFormMapping>(
        `${this.config.rootUrl}${urlSlug}/QCFormMappings`,
        qCFormMapping,
        this.generateHeaders());
  }

  public getQCFormMapping = (corporationID: string, qCFormMappingID: string) => {
      var urlSlug = `/Corporations/${corporationID}`

      return this.http.get<QCFormMapping>(
          `${this.config.rootUrl}${urlSlug}/QCFormMappings/${qCFormMappingID}`,
          this.generateHeaders());
  }

  public getQCFormMappings = (corporationID: string) => {
    var urlSlug = `/Corporations/${corporationID}`

    return this.http.get<QCFormMapping[]>(
        `${this.config.rootUrl}${urlSlug}/QCFormMappings`,
        this.generateHeaders());
  }

  public updateQCFormMapping = (corporationID: string, qCFormMappingID: string, qCFormMapping: QCFormMappingForUpdate) => {
      var urlSlug = `/Corporations/${corporationID}`

      return this.http.put<QCFormMappingForUpdate>(
          `${this.config.rootUrl}${urlSlug}/QCFormMappings/${qCFormMappingID}`,
          qCFormMapping,
          this.generateHeaders());
  }

  public deleteQCFormMapping = (corporationID: string, qCFormMappingID: string) => {
      var urlSlug = `/Corporations/${corporationID}`

      return this.http.delete(
          `${this.config.rootUrl}${urlSlug}/QCFormMappings/${qCFormMappingID}`,
          this.generateHeaders());
  }
}
