<div class="padding">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <div class="flex-container-space-between">
                    <h3>Batches Overview</h3>
                    <mat-checkbox [(ngModel)]="includeCompleted" (change)="isCompletedFilterChange()">
                        Show Completed
                    </mat-checkbox>
                </div>

                <!-- Databases overview table -->
                <table mat-table [dataSource]="this.repo.resource$" multiTemplateDataRows
                    *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0">

                    <ng-container matColumnDef="batchType">
                        <th mat-header-cell *matHeaderCellDef id="batchType"> Type </th>
                        <td mat-cell *matCellDef="let batchSummary"> {{batchSummary.batchTypeID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="batchState">
                        <th mat-header-cell *matHeaderCellDef id="batchState"> State </th>
                        <td mat-cell *matCellDef="let batchSummary"> {{batchSummary.batchStateID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="draftOrders">
                        <th mat-header-cell *matHeaderCellDef id="draftOrders"> Draft </th>
                        <td mat-cell *matCellDef="let batchSummary">
                            <button mat-raised-button [disabled]="batchSummary.draftOrders === 0"
                                [ngStyle]="getButtonColor(batchSummary.draftOrders)"
                                (click)="onViewDraftOrders(batchSummary)">
                                {{ batchSummary.draftOrders }}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pendingOrders">
                        <th mat-header-cell *matHeaderCellDef id="pendingOrders"> Pending </th>
                        <td mat-cell *matCellDef="let batchSummary">
                            <button mat-raised-button [disabled]="batchSummary.pendingOrders === 0"
                                [ngStyle]="getButtonColor(batchSummary.pendingOrders)"
                                (click)="onViewPendingOrders(batchSummary)">
                                {{ batchSummary.pendingOrders }}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="completedOrders">
                        <th mat-header-cell *matHeaderCellDef id="completedOrders"> Completed </th>
                        <td mat-cell *matCellDef="let batchSummary">
                            <button mat-raised-button [disabled]="batchSummary.completedOrders === 0"
                                color="primary"
                                (click)="onViewCompletedOrders(batchSummary)">
                                {{ batchSummary.completedOrders }}
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalOrders">
                        <th mat-header-cell *matHeaderCellDef id="totalOrders"> Total </th>
                        <td mat-cell *matCellDef="let batchSummary">
                            <button mat-raised-button [disabled]="batchSummary.totalOrders === 0"
                                color="primary"
                                (click)="viewBatch(batchSummary)">
                                {{ batchSummary.totalOrders }}
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator *ngIf="(repo.resourceCount$ | async) ?? 0 > pageSize" [hidePageSize]="true"
                    [pageSize]="pageSize" [length]="this.repo.resourceCount$ | async"
                    [pageIndex]="this.repo.pageNum$ | async" showFirstLastButtons="true"
                    (page)="page($event)"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>