import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsRepositoryService, eEnvelopeStatusType, Envelope, EnvelopeRepositoryService, eOrderStatusType, ListComponent } from 'reg-hub-common';
import { interval, Subject, switchMap, takeUntil, takeWhile, timer, tap, delay } from 'rxjs';
import { EnvelopeOrdersPopupComponent } from '../envelope-orders-popup/envelope-orders-popup.component';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-envelopes',
  templateUrl: './envelopes.component.html',
  styleUrls: ['./envelopes.component.css']
})
export class EnvelopesComponent extends ListComponent<Envelope> implements OnInit, OnDestroy {
  displayedColumns = [
    "logs",
    "added",
    "numOrders",
    "previous",
    "icon",
    "status",
    "next",
    "updateState",
    "document"
  ]

  private onDestroy$ = new Subject<void>();
  protected pageSize = 4;
  protected includeCompleted = false;

  processing = new Map<string, boolean>();
  updateStatus = new Map<string, eEnvelopeStatusType>();

  constructor(
    private dialog: MatDialog,
    protected envelopesRepository: EnvelopeRepositoryService,
    private documentsRepository: DocumentsRepositoryService,
    private router: Router) {
    super(envelopesRepository);
  }

  ngOnInit(): void {
    let params = new HttpParams()
      .set('pageSize', this.pageSize);

    this.repo.getFirstPage(this.setParamsWithoutComplete(params));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setParamsWithoutComplete(params: HttpParams): HttpParams {
    return params
      .append('envelopeStatus', eEnvelopeStatusType.error)
      .append('envelopeStatus', eEnvelopeStatusType.open)
      .append('envelopeStatus', eEnvelopeStatusType.sealed)
      .append('envelopeStatus', eEnvelopeStatusType.imported)
      .append('envelopeStatus', eEnvelopeStatusType.resultsPending)
  }

  setParamsWithComplete(params: HttpParams): HttpParams {
    return this.setParamsWithoutComplete(params)
      .append('envelopeStatus', eEnvelopeStatusType.complete);
  }

  onCompletedFilterChange() {
    if (this.includeCompleted) {
      this.repo.filter(this.setParamsWithComplete(new HttpParams()));
    } else {
      this.repo.filter(this.setParamsWithoutComplete(new HttpParams()));
    }
  }

  viewLogs(envelope: Envelope) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['logs'], { queryParams: { tab: 'changes', entityID: envelope.id, entityName: 'Envelope' } })
    );

    window.open(url, '_blank');
  }

  viewOrders(envelope: Envelope) {
    this.dialog.open(EnvelopeOrdersPopupComponent, {
      data: envelope,
      minWidth: '800px'
    })
  }

  downloadDocument(envelope: Envelope) {
    if (!envelope.document) {
      return;
    }

    this.documentsRepository.viewAndDownloadDocumentWithType(envelope.document.id, "application/xml");
  }

  previous(envelope: Envelope) {
    this.envelopesRepository.previous(envelope.id).subscribe();
    this.startPolling(envelope);
  }

  next(envelope: Envelope) {
    this.envelopesRepository.next(envelope.id).subscribe();
    this.startPolling(envelope);
  }

  private startPolling(envelope: Envelope) {
    this.processing.set(envelope.id, true);
    const currentStatus = envelope.envelopeStatus.id;

    let refresh$ = interval(2500)
      .pipe(
        takeUntil(this.onDestroy$),
        takeUntil(timer(90000)),
        switchMap(() => this.envelopesRepository.refreshSubscribable()),
        takeWhile(envelopes => envelopes.find(env => env.id === envelope.id)?.envelopeStatus.id === currentStatus))
      .subscribe({ complete: () => this.processing.set(envelope.id, false) });
  }

  saveStatus(envelope: Envelope) {
    this.processing.set(envelope.id, true);
    const newStatus = this.updateStatus.get(envelope.id);

    if(!newStatus) {
      return;
    }

    this.envelopesRepository.patchEnvelope(envelope.id, [
      {
        op: "replace",
        value: newStatus,
        path: "envelopeStatus"
      }
    ]).pipe(
      takeUntil(this.onDestroy$),
      switchMap(() => this.envelopesRepository.refreshSubscribable())
    ).subscribe({ complete: () => this.processing.set(envelope.id, false) });
  }

  isProcessing(envelope: Envelope) {
    return this.processing.get(envelope.id);
  }

  onStatusUpdateChange(event: any, envelope: Envelope) {
    const newStatus = (event.target as HTMLSelectElement).value;
    this.updateStatus.set(envelope.id, newStatus as eEnvelopeStatusType);
  }

  getIcon(envelope: Envelope) {
    switch (envelope.envelopeStatus.id) {
      case eEnvelopeStatusType.open:
        return 'folder_open';
      case eEnvelopeStatusType.sealed:
        return 'folder';
      case eEnvelopeStatusType.error:
        return 'error_outline';
      case eEnvelopeStatusType.imported:
        return 'history';
      case eEnvelopeStatusType.resultsPending:
        return 'archive';
      case eEnvelopeStatusType.complete:
        return 'check_circle_outline';
      default:
        return '';
    }
  }

  shouldDisableStatusManagement(envelope: Envelope): boolean {
    if(envelope.envelopeOrders.some(eo => eo.orderStatus === eOrderStatusType.manualFulfillment || eo.orderStatus === eOrderStatusType.failedAutomation)) {
      return false;
    } else {
    return envelope.envelopeStatus.id !== 'Error';
    }
  }
}
