import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { CorporationNote } from '../../interfaces/corporation-note/corporation-note';
import { CorporationNoteForCreation } from '../../interfaces/corporation-note/corporation-note-for-creation';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CorporationNoteForUpdate } from '../../interfaces/corporation-note/corporation-note-for-update';
import { PaginatedRepository } from '../pagination/paginated.repository';

@Injectable({
    providedIn: 'root'
})
export class CorporationNoteRepositoryService extends PaginatedRepository<CorporationNote> {

    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(http, messageService);

        this._baseLinkTemplate = `${config.rootUrl}/Corporations/[0]/Notes`;
        this._baseLink = this._baseLinkTemplate;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    private getURL = (corporationID: string, noteID?: string) => {
        this.populateBaseLink([corporationID]);

        if (noteID) {
            return `${this._baseLink}/${noteID}`;
        }
        return this._baseLink;
    }

    public getCorporationNotes = (corporationID: string, httpParams: HttpParams) => {
        return this.http.get<CorporationNote[]>(this.getURL(corporationID), { params: httpParams });
    }

    public getCorporationNote = (corporationID: string, noteID: string) => {
        return this.http.get<CorporationNote[]>(this.getURL(corporationID, noteID));
    }

    public createCorporationNote = (corporationID: string, newNote: CorporationNoteForCreation) => {
        return this.http.post<CorporationNote>(
            this.getURL(corporationID),
            newNote,
            this.generateHeaders());
    }

    public updateCorporationNote = (corporationID: string, noteID: string, updatedNote: CorporationNoteForUpdate) => {
        return this.http.put<CorporationNote>(
            this.getURL(corporationID, noteID),
            updatedNote,
            this.generateHeaders());
    }

    public deleteCorporationNote = (corporationID: string, noteID: string) => {
        return this.http.delete<CorporationNote>(this.getURL(corporationID, noteID));
    }
}
