import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { RouteOnePartyMapping } from '../../interfaces/route-one-party-mappings/route-one-party-mapping';
import { RouteOnePartyMappingForCreation } from '../../interfaces/route-one-party-mappings/route-one-party-mapping-for-creation';
import { RouteOnePartyMappingForUpdate } from '../../interfaces/route-one-party-mappings/route-one-party-mapping-for-update';

@Injectable({
  providedIn: 'root'
})
export class RouteOnePartyMappingRepositoryService extends PaginatedRepository<RouteOnePartyMapping> {
  protected override _baseLink: string;

  constructor(
      http: HttpClient,
      messageService: ToastrService,
      @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
      
      super(http,messageService);

      this._currentParams = new HttpParams()
          .set('pageSize', 10);

      this._baseLinkTemplate = `${config.rootUrl}/RouteOnePartyMappings`;
      this._baseLink = this._baseLinkTemplate;
  }

  private generateHeaders = () => {
      return {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }
  }

  public createRouteOnePartyMapping = (routeOnePartyMapping: RouteOnePartyMappingForCreation) => {

    return this.http.post<RouteOnePartyMapping>(
        `${this.config.rootUrl}/RouteOnePartyMappings`,
        routeOnePartyMapping,
        this.generateHeaders());
  }

  public getRouteOnePartyMapping = (routeOnePartyMappingID: string) => {
      return this.http.get<RouteOnePartyMapping>(
          `${this.config.rootUrl}/RouteOnePartyMappings/${routeOnePartyMappingID}`,
          this.generateHeaders());
  }

  public getRouteOnePartyMappings = () => {
    return this.http.get<RouteOnePartyMapping[]>(
        `${this.config.rootUrl}/RouteOnePartyMappings`,
        this.generateHeaders());
  }

  public updateRouteOnePartyMapping = (routeOnePartyMappingID: string, routeOnePartyMapping: RouteOnePartyMappingForUpdate) => {
      return this.http.put<RouteOnePartyMappingForUpdate>(
          `${this.config.rootUrl}/RouteOnePartyMappings/${routeOnePartyMappingID}`,
          routeOnePartyMapping,
          this.generateHeaders());
  }

  public deleteRouteOnePartyMapping = (routeOnePartyMappingID: string) => {
    return this.http.delete(
              `${this.config.rootUrl}/RouteOnePartyMappings/${routeOnePartyMappingID}`,
              this.generateHeaders());
  }
}
