import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaginatedRepository } from '../../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../../reg-hub-common-config';
import { CustomerPricingReport } from '../../../interfaces/reports/customer-pricing-report';


@Injectable({
  providedIn: 'root'
})
export class CustomerPricingReportRepositoryService extends PaginatedRepository<CustomerPricingReport> {

    protected override _baseLink: string;

  constructor(
    @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig,
        http: HttpClient,
        messageService: ToastrService) {
        super(http, messageService);
        this._baseLink = `${config.rootUrl}/Reports/CustomerPricing`;
  }
}
