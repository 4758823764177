import { Inject, Injectable } from '@angular/core';
import { PaginatedRepository } from '../pagination/paginated.repository';
import { Batch } from '../../interfaces/batches/batch';
import { BatchForUpdate } from '../../interfaces/batches/batch-for-update';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';

@Injectable({
    providedIn: 'root'
})
export class BatchRepositoryService extends PaginatedRepository<Batch> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(http, messageService);

        this._baseLinkTemplate = `${config.batchRootURL}/Collections/[0]/Batches`;
        this._baseLink = `${config.batchRootURL}/Batches`;
    }

    private generateHeaders = () => {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    public getBatch = (collectionID: string, batchID: string) => {
        return this.http.get<Batch>(
            `${this.config.batchRootURL}/Collections/${collectionID}/Batches/${batchID}`, 
            this.generateHeaders());
    }

    public updateBatch = (collectionID: string, batchID: string, batch: BatchForUpdate) => {
        return this.http.put<Batch>(
            `${this.config.batchRootURL}/Collections/${collectionID}/Batches/${batchID}`, 
            batch, 
            this.generateHeaders());
    }
}