import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { PaginatedRepository } from '../../services/pagination/paginated.repository';
import { REG_HUB_COMMON_CONFIG, RegHubCommonConfig } from '../../reg-hub-common-config';
import { BatchLog } from '../../interfaces/batch-logs/batch-log';

@Injectable({
    providedIn: 'root'
})
export class BatchLogsRepositoryService extends PaginatedRepository<BatchLog> {
    protected override _baseLink: string;

    constructor(
        http: HttpClient,
        messageService: ToastrService,
        @Inject(REG_HUB_COMMON_CONFIG) private config: RegHubCommonConfig) {
        super(
            http,
            messageService);

        this._baseLink = `${this.config.rootUrl}/Logs/Batches`;
    }
}