import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { InternalSearchCriteria, NavItem } from 'reg-hub-common';
import { ConfigurationState } from 'reg-hub-common';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  @Input() isSidenavOpen: boolean = true;

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer!: ToastContainerDirective

  selectedItem = '';
  isSearching = false;

  // This will come from a DB call to which nav items a user is allowed to see
  navItems: NavItem[] = [
    { label: 'Dashboard', icon: 'home', link: '/dashboard' },
    { label: 'Orders', icon: 'search', link: '/orders' },
    { label: 'Batches', icon: 'library_books', link: '/batches' },
    { label: 'Corporations', icon: 'apartment', link: '/corporations' },
    { label: 'Pricing', icon: '$', link: '/pricing' },
    { label: 'Invoicing', icon: 'payments', link: '/invoices' },
    { label: 'Credentials', icon: 'vpn_key', link: '/registry-credentials' },
    { label: 'Reports', icon: 'assessment', link: '/reports' },
    { label: 'Logs', icon: 'book', link: '/logs' },
    { label: 'System', icon: 'settings', link: '/system' },
    { label: 'Clients', icon: 'webhook', link: '/clients' }
  ];

  constructor(
    private messageService: ToastrService,
    private router: Router,
    private configurationState: ConfigurationState) { }

  ngOnInit(): void {
    this.messageService.overlayContainer = this.toastContainer;

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.messageService.clear());
  }

  handleClick(selectedItem: NavItem) {
    this.selectedItem = selectedItem.label;
  }

  onSearch(search: InternalSearchCriteria) {
    this.isSearching = true;

    switch (search.typeOfSearch) {
        case 'referenceNumber':
            this.searchByReferenceNumber(search.criteria);
            break;
        case 'firstName':
            this.searchByFirstName(search.criteria);
            break;
        case 'lastName':
            this.searchByLastName(search.criteria);
            break;
        case 'businessName':
            this.searchByBusinessName(search.criteria);
            break;
        case 'serialNumber':
            this.searchBySerialNumber(search.criteria);
            break;
        case 'dateOfBirth':
            this.searchByDateOfBirth(search.criteria);
            break;
        case 'orderId':
            this.searchByOrderId(search.criteria);
            break;
        case 'registrationOrFileNumber':
            this.searchByRegistrationOrFileNumber(search.criteria);
            break;
        case 'externalOrderNumber':
            this.searchByExternalOrderNumber(search.criteria);
            break;
        default:
            console.error('Invalid search option');
            break;
    }
  }

  searchByReferenceNumber(referenceNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      referenceNumber: referenceNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByFirstName(firstName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      firstName: firstName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByLastName(lastName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      lastName: lastName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByBusinessName(businessName: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      businessName: businessName,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchBySerialNumber(serialNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      serialNumber: serialNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByDateOfBirth(dateOfBirth: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      dateOfBirth: dateOfBirth,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByRegistrationOrFileNumber(registrationNumber: string) {
    const lienOrderTypes = [
      'Lien',
      'Renewal',
      'Amendment',
      'Discharge',
      'BasicRenewal',
      'BasicDischarge'
  ];

    let params = {
      autoExpand: false,
      autoRedirect: true,
      orderTypes: lienOrderTypes,
      registrationOrFileNumber: registrationNumber
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByExternalOrderNumber(externalOrderNumber: string) {
    let params = {
      autoExpand: false,
      autoRedirect: true,
      externalOrderNumber: externalOrderNumber,
    }

    this.router.navigate(['/orders'], { queryParams: params })
  }

  searchByOrderId(orderId: string) {
      this.router.navigate(['orders', orderId]);
  }
}
