import { Component, Input } from '@angular/core';
import { Order } from '../../interfaces/orders/order';
import { OrderMetaData } from '../../interfaces/order-meta-data';

@Component({
  selector: 'lib-order-requesting-user-details',
  templateUrl: './order-requesting-user-details.component.html',
  styleUrls: ['./order-requesting-user-details.component.css']
})
export class OrderRequestingUserDetailsComponent {
  @Input() order!: Order
  @Input() orderMetaData!: OrderMetaData

  ngOnInit(): void {
  }
}
