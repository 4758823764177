<div class="padding">
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <div class="flex-container-space-between">
                    <h3>Envelopes Overview</h3>
                    <mat-checkbox [(ngModel)]="includeCompleted" (change)="onCompletedFilterChange()">
                        Show Completed
                    </mat-checkbox>
                </div>

                <!-- Databases overview table -->
                <table mat-table [dataSource]="this.repo.resource$" multiTemplateDataRows
                    *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0">

                    <ng-container matColumnDef="logs">
                        <th mat-header-cell *matHeaderCellDef id="logs"> </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" (click)="viewLogs(envelope)">
                                    Logs
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="added">
                        <th mat-header-cell *matHeaderCellDef id="added">
                            <div class="flex-container-center">
                                Creation Date
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                {{ envelope.added | localizedDate }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="numOrders">
                        <th mat-header-cell *matHeaderCellDef id="numOrders">
                            <div class="flex-container-center">
                                Orders
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" (click)="viewOrders(envelope)">
                                    {{ envelope.envelopeOrders.length }}
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="previous">
                        <th mat-header-cell *matHeaderCellDef id="previous"> </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" [disabled]="!envelope.envelopeStatus.isPreviousAvailable || isProcessing(envelope)" (click)="previous(envelope)">
                                    {{ envelope.envelopeStatus.previousActionName ?? 'Prev' }}
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="icon">
                        <th mat-header-cell *matHeaderCellDef id="icon"> </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <mat-icon *ngIf="!isProcessing(envelope); else importingIcon">{{ getIcon(envelope) }}</mat-icon>
                                <ng-template #importingIcon>
                                    <div class="spinner-inline-container">
                                        <div class="spinner-inline"> </div>
                                    </div>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef id="status">
                            <div class="flex-container-center">
                                Status
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <span *ngIf="!isProcessing(envelope); else importing">{{ envelope.envelopeStatus.id }}</span>
                                <ng-template #importing>
                                    <span>Processing...</span>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="next">
                        <th mat-header-cell *matHeaderCellDef id="next"> </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" [disabled]="!envelope.envelopeStatus.isNextAvailable || isProcessing(envelope)" (click)="next(envelope)">
                                    {{ envelope.envelopeStatus.nextActionName ?? 'Next' }}
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="updateState">
                        <th mat-header-cell *matHeaderCellDef id="updateState">
                            <div class="flex-container-center">
                                Manage State
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            
                            <div class="custom-select-container">
                                <select class="custom-select" id="statusUpdateSelect" [disabled]="shouldDisableStatusManagement(envelope)" class="form-control" (change)="onStatusUpdateChange($event, envelope)">
                                    <option> </option>
                                    <option value="Open">Open</option>
                                    <option value="Sealed">Sealed</option>
                                    <option value="Imported">Imported</option>
                                    <option value="ResultsPending">ResultsPending</option>
                                    <option value="Complete">Complete</option>
                                </select>
                                <button mat-icon-button color="primary" [disabled]="shouldDisableStatusManagement(envelope) || !updateStatus.get(envelope.id)" (click)="saveStatus(envelope)"><mat-icon>save</mat-icon></button>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="document">
                        <th mat-header-cell *matHeaderCellDef id="document">
                            <div class="flex-container-center">
                                Document
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let envelope">
                            <div class="flex-container-center">
                                <a mat-flat-button color="primary" (click)="downloadDocument(envelope)"
                                    [disabled]="!envelope.document">Download</a>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator *ngIf="(repo.resourceCount$ | async) ?? 0 > pageSize" [hidePageSize]="true"
                    [pageSize]="pageSize" [length]="this.repo.resourceCount$ | async"
                    [pageIndex]="this.repo.pageNum$ | async" showFirstLastButtons="true"
                    (page)="page($event)"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>