<h4>Requesting User Details</h4>

<div class="flex-container">
    <span class="flex-item">User:</span>
    <a
        class="flex-item-2 link-style"
        [routerLink]="['/corporations', order.orderGroup?.corporationID, 'usergroups', order.userGroupID, 'users', order.userID]"
        aria-label="User Group Link">
        {{ orderMetaData.requestingUserFullName ?? '' }} ({{ orderMetaData.requestingUserType ?? '' }} - {{ orderMetaData.requestingUserName ?? '' }})
    </a>
</div>
<div class="flex-container">
    <span class="flex-item">Email:</span><span class="flex-item-2">{{ orderMetaData.requestingUserEmail ?? '' }}</span>
</div>
<div class="flex-container">
    <span class="flex-item">User Group:</span>
    <a
        class="flex-item-2 link-style"
        [routerLink]="['/corporations', order.orderGroup?.corporationID, 'usergroups', order.userGroupID]"
        aria-label="User Group Link">
        {{ orderMetaData.requestingUserGroupName ?? '' }}
    </a>
</div>
<div class="flex-container">
    <span class="flex-item">Corporation:</span>
    <a
        *ngIf="order.orderGroup?.corporationID"
        class="flex-item-2 link-style"
        [routerLink]="['/corporations', order.orderGroup?.corporationID]"
        aria-label="Corporation Link">
        {{ orderMetaData.requestingCorporationName ?? '' }}
    </a>
</div>